import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cm-filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrls: ['./filter-pill.component.scss'],
})
export class FilterPillComponent {
  @Input()
  public filterName?: string;

  @Input()
  public filterValue?: string;

  @Output() filterRemoved = new EventEmitter();

  onCancelFilter(): void {
    this.filterRemoved.emit();
  }
}
