<section class="navigation">
  <div class="nav-header">
    <div id="sidebar-logo" class="logo">
      <img height="57px" width="115px" class="logo" alt="logo" src="assets/images/logo.svg" />
    </div>
  </div>

  <div id="navigation-links" class="navigation-menu">
    <div id="campaigns-link"
      class="nav-item"
      [routerLink]="['/campaigns']"
      routerLinkActive="active">
      Campaigns <i class="icon-list"></i>
    </div>
  </div>

  <div class="login-area">
    <div class="login-logout-button">
      <button mat-button *ngIf="!authenticated" (click)="login()">
        <span class="button-label">Login</span>
        <i class="icon-log-in"></i>
      </button>
      <button mat-button *ngIf="authenticated" (click)="logout()">
        <i class="icon-log-out"></i>
        <span class="button-label">Logout</span>
      </button>
    </div>
  </div>
</section>
