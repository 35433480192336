import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cm-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent {
  @Input() titleText?: string;
  @Input() cancelText?: string;
  @Input() confirmText?: string;
  @Input() cancelIcon?: boolean;
  @Output() cancelClicked = new EventEmitter();
  @Output() confirmClicked = new EventEmitter();

  onConfirmClick(): void {
    this.confirmClicked.emit();
  }

  onCancelClick(): void {
    this.cancelClicked.emit();
  }
}
