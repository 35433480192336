import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'cm-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() public totalCount?: number;
  @Input() public pageIndex?: number;
  @Input() public pageSize?: number;

  @Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter();

  onPageChange(e: PageEvent) {
    this.pageChanged.emit(e);
  }
}
