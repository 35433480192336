import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/material.module';
import * as components from '@shared/ui';
import * as directives from '@shared/directives';

@NgModule({
  declarations: [
    components.BannerComponent,
    components.ConfirmationBoxComponent,
    components.FileUploadComponent,
    components.FilterBoxComponent,
    components.FilterPillComponent,
    components.HeaderComponent,
    components.InfoAlertComponent,
    components.PaginatorComponent,
    components.RunStatusComponent,
    components.SidebarComponent,
    directives.DndDirective,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, RouterModule],
  exports: [
    components.BannerComponent,
    components.ConfirmationBoxComponent,
    components.FileUploadComponent,
    components.FilterBoxComponent,
    components.FilterPillComponent,
    components.HeaderComponent,
    components.InfoAlertComponent,
    components.PaginatorComponent,
    components.RunStatusComponent,
    components.SidebarComponent,
    directives.DndDirective,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
