import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'cm-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
})
export class FilterBoxComponent {
  @Input() hasFilters?: boolean;

  @ViewChild('backdrop', { read: ElementRef }) backdrop?: ElementRef;

  menuOpen = false;

  public onClose() {
    this.menuOpen = false;
  }

  @HostListener('click', ['$event'])
  @HostListener('document:mousewheel', ['$event'])
  public onWindowEvent($event: Event) {
    if (this.menuOpen && this.backdrop?.nativeElement.contains($event.target)) {
      $event.stopPropagation();
      this.onClose();
    }
  }

  public toggleFilterMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
