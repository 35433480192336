import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import * as AuthActions from '@core/data-access';
import { AuthState } from '@core/data-access';

@Component({
  selector: 'cm-root',
  template: `
    <cm-sidebar
      [authenticated]="!!(authenticated$ | async)"
      [loggedInUser]="(loggedInUser$ | async) ?? undefined"
      (loginClicked)="login()"
      (logoutClicked)="logout()"
    ></cm-sidebar>
    <main class="router-outlet-wrapper">
      <router-outlet></router-outlet>
    </main>
  `,
  styles: [
    `
      @import './src/variables';

      :host {
        display: flex;
        height: 100vh;
        flex-grow: 1;

        .router-outlet-wrapper {
          flex: 1 0 0;
          height: 100vh;
          display: flex;
          position: relative;
          background-color: $color-eggshell;
        }
      }
    `,
  ],
})
export class AppContainer implements OnInit {
  @Select(AuthState.authenticated)
  public authenticated$!: Observable<boolean>;

  @Select(AuthState.loggedInUser)
  public loggedInUser$!: Observable<string>;

  title = 'campaign-manager-web';
  isIframe = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new AuthActions.CheckAuthStatus());
  }

  login() {
    this.store.dispatch(new AuthActions.Login());
  }

  logout() {
    this.store.dispatch(new AuthActions.Logout());
  }
}
