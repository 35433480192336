import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cm-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss'],
})
export class InfoAlertComponent {
  @Input()
  public featherIcon: string | undefined;

  @Input()
  public title: string | undefined;

  @Input()
  public description: string | undefined;

  @Input()
  public buttonText: string | undefined;

  @Output() buttonClicked = new EventEmitter();

  public handleButtonClick() {
    this.buttonClicked.emit();
  }
}
