import { Inject, Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { filter } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';

import * as AuthActions from '@core/data-access/store/actions/auth.actions';
import { AuthStateModel } from '@core/data-access/models/auth-state.model';

const defaults = {
  authenticated: false,
  loggedInUser: undefined,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class AuthState {
  public constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService
  ) {}

  @Selector()
  public static authenticated(state: AuthStateModel) {
    return state.authenticated;
  }

  @Selector()
  public static loggedInUser(state: AuthStateModel) {
    return state.loggedInUser;
  }

  @Action(AuthActions.CheckAuthStatus)
  checkAuthStatus(ctx: StateContext<AuthStateModel>) {
    return this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        const authenticated = this.authService.instance.getAllAccounts().length > 0;
        const username = authenticated ? this.authService.instance.getAllAccounts()[0].username : undefined;
        ctx.patchState({
          authenticated: authenticated,
          loggedInUser: username,
        });
      });
  }

  @Action(AuthActions.Login)
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  @Action(AuthActions.Logout)
  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }
}
