import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() authenticated? = false;
  @Input() loggedInUser?: string;
  @Output() loginClicked = new EventEmitter();
  @Output() logoutClicked = new EventEmitter();

  login() {
    this.loginClicked.emit();
  }

  logout() {
    this.logoutClicked.emit();
  }
}
