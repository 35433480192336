<div id="header-container" class="container">
  <section *ngIf="loggedInUser$ | async else notLoggedIn" id="current-user" class="current-user">
    <div class="user-picture">
      <i class="icon-user"></i>
    </div>
    {{loggedInUser$ | async}}
  </section>
  <ng-template #notLoggedIn>
    <section id="user-not-logged-in" class="current-user">
      <div class="user-picture">
        <i class="icon-user"></i>
      </div>
      You are not logged in.
    </section>
  </ng-template>
  <section id="page-header" class="header">
    <div id="header-title" class="page-title">
      {{title}}
    </div>
    <div id="header-content" class="content">
      <ng-content></ng-content>
    </div>
  </section>
</div>
