import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular'; // MsalGuard added to imports
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'; // InteractionType added to imports

import { SharedModule } from '@shared/shared.module';
import { environment } from '@environments/environment';
import { AuthState } from '@core/data-access';
import { EnvConfig } from '@core/constants/environment.constant';

import { AppContainer } from './app.container';
import { AppRoutingModule } from './app-routing.module';

const request = new XMLHttpRequest();
request.open('GET', '/assets/app-config', false); // request application settings synchronous
request.send(null);
const env = JSON.parse(request.responseText) as EnvConfig;
const protectedResourceMap = new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]);

if (environment.enableMsal) {
  protectedResourceMap.set('/api/', [env.azure.applicationId + '/user']);
}

@NgModule({
  declarations: [AppContainer],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: env.azure.applicationId, // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/' + env.azure.tenantId, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: '/', // This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false, // No support Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: protectedResourceMap,
      }
    ),
    NgxsModule.forRoot([AuthState], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
  bootstrap: [AppContainer, MsalRedirectComponent],
})
export class AppModule {}
