import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { environment } from '@environments/environment';

const guards = environment.enableMsal ? [MsalGuard] : [];

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'campaigns' },
  {
    path: 'campaigns',
    loadChildren: () => import('@campaigns/campaigns.module').then((m) => m.CampaignsModule),
    data: { preload: true },
    canActivate: guards,
  },
];
