import { Component, Input } from '@angular/core';

import { CampaignRunStatus } from '@campaigns/models';

@Component({
  selector: 'cm-run-status',
  templateUrl: './run-status.component.html',
  styleUrls: ['./run-status.component.scss'],
})
export class RunStatusComponent {
  @Input() campaignRunStatus?: CampaignRunStatus | null;

  public getPercent(status: string): number {
    if (!this.campaignRunStatus) {
      return 0;
    }

    const total = this.getTotal();

    if (total === 0) {
      return 0;
    }

    switch (status) {
      case 'initialized':
        return ((this.campaignRunStatus.initialized || 0) / total) * 100 || 0;
      case 'passed':
        return ((this.campaignRunStatus.passed || 0) / total) * 100 || 0;
      case 'failed':
        return ((this.campaignRunStatus.failed || 0) / total) * 100 || 0;
      case 'partial':
        return ((this.campaignRunStatus.partial || 0) / total) * 100 || 0;
      case 'preprocessing':
        return ((this.campaignRunStatus.preprocessing || 0) / total) * 100 || 0;
      case 'preprocessingError':
        return ((this.campaignRunStatus.preprocessingError || 0) / total) * 100 || 0;
      default:
        return 0;
    }
  }

  public getTotal(): number {
    if (!this.campaignRunStatus) {
      return 0;
    }
    const initialized = this.campaignRunStatus.initialized || 0;
    const passed = this.campaignRunStatus.passed || 0;
    const failed = this.campaignRunStatus.failed || 0;
    const partial = this.campaignRunStatus.partial || 0;
    const preprocessing = this.campaignRunStatus.preprocessing || 0;
    const preprocessingError = this.campaignRunStatus.preprocessingError || 0;
    return initialized + failed + passed + partial + preprocessing + preprocessingError;
  }
}
