<div id="confirmation-box">
  <div mat-dialog-title class="confirmation-header">
    <h1 class="confirmation-title">{{ titleText }}</h1>
    <div class="confirmation-x" (click)="onCancelClick()">
      <i class="icon-x"></i>
    </div>
  </div>
  <mat-dialog-content>
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions id="confirmation-actions">
    <button
      id="confirmation-cancel"
      type="button"
      [ngClass]="cancelIcon ? 'close' : 'secondary'"
      (click)="onCancelClick()"
      cdkFocusInitial
    >
      {{ cancelText }}
      <i [ngClass]="cancelIcon ? 'icon-slash' : 'icon-edit'"></i>
    </button>
    <button
      id="confirmation-confirm"
      type="button"
      (click)="onConfirmClick()"
      cdkFocusInitial
    >
      {{ confirmText }}
      <i class="icon-check"></i>
    </button>
  </mat-dialog-actions>
</div>
