
<div class="filter-button" [ngClass]="{ active: hasFilters }" (click)="toggleFilterMenu()">
  Filters
  <i class="icon-filter"></i>
</div>
<div class="filter-menu" *ngIf="menuOpen">
  <div class="filters-header">
    Filters
    <i class="icon-x" (click)="onClose()"></i>
  </div>
  <div class="filters">
    <ng-content></ng-content>
  </div>
</div>
<div class="filter-backdrop" *ngIf="menuOpen" #backdrop></div>