<div class="run-status" *ngIf="campaignRunStatus && getTotal() > 0">
  <div class="run-status-title">Status</div>
  <div class="run-status-bar">
    <div *ngIf="(campaignRunStatus.initialized || 0) > 0" class="run-status-bar-initialized" [style]="{ width: getPercent('initialized') + '%' }"></div>
    <div *ngIf="(campaignRunStatus.passed || 0) > 0" class="run-status-bar-passed" [style]="{ width: getPercent('passed') + '%' }"></div>
    <div *ngIf="(campaignRunStatus.failed || 0) > 0" class="run-status-bar-failed" [style]="{ width: getPercent('failed') + '%' }"></div>
    <div *ngIf="(campaignRunStatus.partial || 0) > 0" class="run-status-bar-partial" [style]="{ width: getPercent('partial') + '%' }"></div>
    <div *ngIf="(campaignRunStatus.preprocessing || 0) > 0" class="run-status-bar-preprocessing" [style]="{ width: getPercent('preprocessing') + '%' }"></div>
    <div *ngIf="(campaignRunStatus.preprocessingError || 0) > 0" class="run-status-bar-preprocessing-error" [style]="{ width: getPercent('preprocessingError') + '%' }"></div>
  </div>
  <div class="run-status-legend">
    <div class="run-status-legend-initialized">
      <span class="example"></span>
      <span class="label">Initialized:</span>
      <span class="count">{{ campaignRunStatus.initialized || 0 }}</span>
    </div>
    <div class="run-status-legend-passed">
      <span class="example"></span>
      <span class="label">Passed:</span>
      <span class="count">{{ campaignRunStatus.passed || 0 }}</span>
    </div>
    <div class="run-status-legend-failed">
      <span class="example"></span>
      <span class="label">Failed:</span>
      <span class="count">{{ campaignRunStatus.failed || 0 }}</span>
    </div>
    <div class="run-status-legend-partial">
      <span class="example"></span>
      <span class="label">Partial:</span>
      <span class="count">{{ campaignRunStatus.partial || 0 }}</span>
    </div>
    <div class="run-status-legend-preprocessing">
      <span class="example"></span>
      <span class="label">Preprocessing:</span>
      <span class="count">{{ campaignRunStatus.preprocessing || 0 }}</span>
    </div>
    <div class="run-status-legend-preprocessing-error">
      <span class="example"></span>
      <span class="label">Preprocessing Error:</span>
      <span class="count">{{ campaignRunStatus.preprocessingError || 0 }}</span>
    </div>
    <div class="run-status-legend-total">
      <span class="label">Total:</span>
      <span class="count">{{ getTotal() }}</span>
    </div>
</div>
