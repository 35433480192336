import { Component, Input } from '@angular/core';

@Component({
  selector: 'cm-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input()
  public isError = false;

  @Input()
  public featherIcon?: string;

  @Input()
  public description?: string;
}
