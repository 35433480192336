<div class="file-upload">
  <div class="file-upload-drop-container" [ngClass]="{'closed' : internalFiles.length > 0}">
    <div class="drop-zone" cmDnd (fileDropped)="onFileSelected($event)" (click)="browse()"></div>
    <input #browseFiles type="file" (change)="handleBrowseFiles($event)" style="display: none;">
    <i class="icon-file-text"></i>
    <div class="drop-zone-label">{{label}}</div>
    <div class="drop-zone-sub-label">or drag and drop it here</div>
  </div>

  <div class="file-upload-list" *ngIf="internalFiles.length > 0">
    <div class="file" *ngFor="let file of internalFiles; index as i">
      <div class="file-details">
        <div class="file-details-name"><span>{{file.name}}</span></div>
      </div>
      <div class="file-actions">
        <button class="remove-file-button secondary error" (click)="removeLastAddedFile()"><i class="icon-delete"></i></button>
      </div>
    </div>
  </div>
</div>
