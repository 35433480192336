import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthState } from '@core/data-access';
import * as AuthActions from '@core/data-access';

@Component({
  selector: 'cm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string | undefined;

  @Select(AuthState.loggedInUser)
  public loggedInUser$!: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new AuthActions.CheckAuthStatus());
  }
}
