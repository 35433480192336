<div
  class="banner"
  [ngClass]="isError ? 'alert' : 'warning'"
>
  <div class="banner-content">
    <i [ngClass]="featherIcon"></i>
    <span class="banner-description">
      <span>{{ description }}</span>
    </span>
  </div>
</div>