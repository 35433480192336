import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[cmDnd]',
})
export class DndDirective {
  @Output() fileDropped = new EventEmitter<FileList>();
  @HostBinding('class.fileover') fileOver = false;

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  ondrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;

    if (e.dataTransfer) {
      const files = e.dataTransfer.files;

      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
