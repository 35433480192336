import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'cm-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() label: string | undefined;

  @Output() filesChanged = new EventEmitter();
  @ViewChild('browseFiles', { static: false }) browseFiles: ElementRef<HTMLInputElement> = {} as ElementRef;

  internalFiles: File[] = [];

  browse() {
    this.browseFiles.nativeElement.click();
  }

  formatBytes(bytes: number) {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  }

  handleBrowseFiles(e: Event) {
    const element = e.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.onFileSelected(fileList);
    }
  }

  onFileSelected(e: FileList) {
    // We only allow uploading one file at a time
    for (let i = 0; i < e.length && this.internalFiles.length === 0; i++) {
      const file = e.item(i);
      if (file !== null) {
        this.internalFiles.push(file);
      }
    }
    this.filesChanged.emit(this.internalFiles);
  }

  public removeLastAddedFile() {
    this.internalFiles.splice(0, 1);
    this.browseFiles.nativeElement.value = '';
    this.filesChanged.emit(this.internalFiles);
  }
}
